import React from 'react';
import { Phone, Mail, Clock } from 'lucide-react';
import CTAButton from './CTAButton';

const contactInfo = [
  {
    icon: Phone,
    title: 'Phone',
    details: ['+1 866 775 1660', '24/7 AI Support']
  },
  {
    icon: Mail,
    title: 'Email',
    details: ['hello@aireceptionistagency.com']
  },
  {
    icon: Clock,
    title: 'Business Hours',
    details: ['AI: 24/7 Availability', 'Human Support: Mon-Fri, 9AM-6PM PST']
  }
];

export default function Contact() {
  return (
    <div id="contact" className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Get in Touch
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Ready to transform your business communication?
          </p>
        </div>

        <div className="mt-16">
          <div className="max-w-3xl mx-auto bg-white rounded-2xl shadow-sm p-8">
            <div className="space-y-8">
              {contactInfo.map((item, index) => {
                const Icon = item.icon;
                return (
                  <div key={index} className="flex items-start">
                    <div className="flex-shrink-0">
                      <div className="h-10 w-10 bg-blue-100 rounded-lg flex items-center justify-center">
                        <Icon className="h-5 w-5 text-blue-600" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-medium text-gray-900">{item.title}</h3>
                      {item.details.map((detail, detailIndex) => (
                        <p key={detailIndex} className="mt-1 text-gray-600">{detail}</p>
                      ))}
                    </div>
                  </div>
                );
              })}

              <div className="pt-8 border-t border-gray-200">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Schedule a Demo</h3>
                <p className="text-gray-600 mb-6">
                  Want to see our AI receptionist in action? Book a personalized demo with our team.
                </p>
                <CTAButton href="https://tidycal.com/3zn4zw3/free-strategy-call-for-ai-receptionist" variant="primary">
                  Book a Demo
                </CTAButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}