{/* Previous imports remain the same, but remove Blog and Press imports */}
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

// Main pages
import Home from './pages/Home';
import Services from './pages/Services';
import Industries from './pages/Industries';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Features from './pages/Features';
import Booking from './pages/Booking';

// Feature pages
import CallAnswering from './pages/features/CallAnswering';
import Scheduling from './pages/features/Scheduling';
import LeadQualification from './pages/features/LeadQualification';
import Messaging from './pages/features/Messaging';
import Integrations from './pages/features/Integrations';
import Analytics from './pages/features/Analytics';
import VoiceAssistant from './pages/features/VoiceAssistant';
import Outreach from './pages/features/Outreach';

// Industry pages
import Legal from './pages/industries/Legal';
import Healthcare from './pages/industries/Healthcare';
import RealEstate from './pages/industries/RealEstate';
import ProfessionalServices from './pages/industries/ProfessionalServices';

// Company pages
import About from './pages/company/About';
import Careers from './pages/company/Careers';

// Integration pages
import SalesforceIntegration from './pages/integrations/Salesforce';
import HubspotIntegration from './pages/integrations/Hubspot';
import ZapierIntegration from './pages/integrations/Zapier';
import SlackIntegration from './pages/integrations/Slack';

// Legal pages
import Privacy from './pages/legal/Privacy';
import Terms from './pages/legal/Terms';
import Cookies from './pages/legal/Cookies';
import GDPR from './pages/legal/GDPR';

export default function App() {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <main className="flex-grow">
        <Routes>
          {/* Main routes */}
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/features" element={<Features />} />
          <Route path="/booking" element={<Booking />} />
          
          {/* Feature routes */}
          <Route path="/features/call-answering" element={<CallAnswering />} />
          <Route path="/features/scheduling" element={<Scheduling />} />
          <Route path="/features/lead-qualification" element={<LeadQualification />} />
          <Route path="/features/messaging" element={<Messaging />} />
          <Route path="/features/integrations" element={<Integrations />} />
          <Route path="/features/analytics" element={<Analytics />} />
          <Route path="/features/voice-assistant" element={<VoiceAssistant />} />
          <Route path="/features/outreach" element={<Outreach />} />

          {/* Industry routes */}
          <Route path="/industries/legal" element={<Legal />} />
          <Route path="/industries/healthcare" element={<Healthcare />} />
          <Route path="/industries/real-estate" element={<RealEstate />} />
          <Route path="/industries/professional-services" element={<ProfessionalServices />} />

          {/* Integration routes */}
          <Route path="/integrations/salesforce" element={<SalesforceIntegration />} />
          <Route path="/integrations/hubspot" element={<HubspotIntegration />} />
          <Route path="/integrations/zapier" element={<ZapierIntegration />} />
          <Route path="/integrations/slack" element={<SlackIntegration />} />

          {/* Company routes */}
          <Route path="/company/about" element={<About />} />
          <Route path="/company/careers" element={<Careers />} />

          {/* Legal routes */}
          <Route path="/legal/privacy" element={<Privacy />} />
          <Route path="/legal/terms" element={<Terms />} />
          <Route path="/legal/cookies" element={<Cookies />} />
          <Route path="/legal/gdpr" element={<GDPR />} />

          {/* Catch-all route - redirect to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}