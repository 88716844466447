import React from 'react';
import { BarChart, LineChart, PieChart, ArrowRight } from 'lucide-react';
import PageLayout from '../../components/PageLayout';

const features = [
  {
    icon: LineChart,
    title: 'Real-time Monitoring',
    description: 'Live tracking of call metrics, usage, and performance.',
    metrics: [
      'Call duration tracking',
      'Usage monitoring',
      'Peak hour analysis',
      'Cost tracking'
    ]
  },
  {
    icon: BarChart,
    title: 'Performance Insights',
    description: 'Detailed analytics on call handling and outcomes.',
    metrics: [
      'Call resolution rates',
      'Response accuracy',
      'Transfer rates',
      'Customer satisfaction'
    ]
  },
  {
    icon: PieChart,
    title: 'Business Intelligence',
    description: 'Actionable insights for business optimization.',
    metrics: [
      'Trend analysis',
      'Usage patterns',
      'Cost optimization',
      'ROI tracking'
    ]
  }
];

const dashboards = [
  {
    title: 'Usage Dashboard',
    metrics: [
      'Minutes used/remaining',
      'Cost per interaction',
      'Peak usage times',
      'Resource utilization'
    ]
  },
  {
    title: 'Performance Dashboard',
    metrics: [
      'Call success rates',
      'Average handle time',
      'First call resolution',
      'Customer feedback'
    ]
  }
];

export default function Analytics() {
  return (
    <PageLayout
      title="Analytics & Insights"
      subtitle="Real-time monitoring and performance tracking powered by VAPI"
    >
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div>
          <div className="mt-6">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className="mt-10 first:mt-0">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <Icon className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {feature.title}
                      </h3>
                      <p className="mt-2 text-base text-gray-500">
                        {feature.description}
                      </p>
                      <ul className="mt-3 space-y-2">
                        {feature.metrics.map((metric, metricIndex) => (
                          <li key={metricIndex} className="flex items-center text-gray-500 text-sm">
                            <div className="h-1.5 w-1.5 rounded-full bg-blue-600 mr-2"></div>
                            {metric}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        
        <div className="mt-10 lg:mt-0">
          <div className="aspect-w-16 aspect-h-9">
            <img
              className="rounded-lg shadow-lg object-cover"
              src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
              alt="Analytics Dashboard"
            />
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="bg-gray-50 rounded-2xl p-8">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold text-gray-900 text-center">
              Real-time Dashboards
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-2">
              {dashboards.map((dashboard, index) => (
                <div key={index} className="bg-white rounded-xl p-6 shadow-sm">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">
                    {dashboard.title}
                  </h3>
                  <ul className="space-y-3">
                    {dashboard.metrics.map((metric, metricIndex) => (
                      <li key={metricIndex} className="flex items-start">
                        <div className="flex-shrink-0">
                          <div className="h-6 w-6 rounded-full bg-blue-100 flex items-center justify-center">
                            <div className="h-2 w-2 rounded-full bg-blue-600"></div>
                          </div>
                        </div>
                        <span className="ml-3 text-gray-600">{metric}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Ready to gain deeper insights?</h2>
            <p className="mt-4 text-gray-600">
              Get real-time visibility into your communication performance with our analytics dashboard.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                View Demo Dashboard <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}