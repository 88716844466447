import React from 'react';
import { Link } from 'react-router-dom';
import { Phone, Brain, MessageSquare, Calendar, Globe2, BarChart, ArrowRight } from 'lucide-react';
import PageLayout from '../components/PageLayout';

const features = [
  {
    icon: Brain,
    title: 'AI Voice Assistant',
    description: 'Enterprise-grade voice AI technology for natural, human-like interactions.',
    link: '/features/voice-assistant'
  },
  {
    icon: Phone,
    title: 'Call Answering',
    description: 'Intelligent call handling and routing available 24/7.',
    link: '/features/call-answering'
  },
  {
    icon: MessageSquare,
    title: 'Outreach Campaigns',
    description: 'AI-powered outbound communication for proactive customer engagement.',
    link: '/features/outreach'
  },
  {
    icon: Calendar,
    title: 'Smart Scheduling',
    description: 'Automated appointment booking and calendar management.',
    link: '/features/scheduling'
  },
  {
    icon: Globe2,
    title: 'Multi-Language Support',
    description: 'Support customers in their preferred language with natural translations.',
    link: '/features/voice-assistant'
  },
  {
    icon: BarChart,
    title: 'Advanced Analytics',
    description: 'Comprehensive insights and reporting on all communications.',
    link: '/features/analytics'
  }
];

export default function Features() {
  return (
    <PageLayout
      title="Features"
      subtitle="Comprehensive AI communication solutions for modern businesses"
    >
      <div className="mt-12 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {features.map((feature, index) => {
          const Icon = feature.icon;
          return (
            <div key={index} className="relative group">
              <div className="h-full flex flex-col bg-white p-8 border border-gray-200 rounded-2xl transition-all duration-300 hover:shadow-lg hover:scale-105">
                <div className="flex items-center justify-center h-12 w-12 rounded-xl bg-blue-100 text-blue-600 mb-4">
                  <Icon className="h-6 w-6" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{feature.title}</h3>
                <p className="text-gray-500 flex-grow">{feature.description}</p>
                <Link 
                  to={feature.link} 
                  className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-700"
                >
                  Learn more <ArrowRight className="ml-2 h-4 w-4" />
                </Link>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-16">
        <div className="bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Ready to transform your business communication?</h2>
            <p className="mt-4 text-gray-600">
              Experience the power of AI-driven communication with our comprehensive feature set.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <Link
                to="/contact"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700"
              >
                Get Started <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <Link
                to="/pricing"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50"
              >
                View Pricing
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}