import React from 'react';
import { Link } from 'react-router-dom';
import { Phone } from 'lucide-react';
import useScrollToTop from '../hooks/useScrollToTop';

const quickLinks = [
  { name: 'Features', path: '/features' },
  { name: 'Pricing', path: '/pricing' },
  { name: 'Industries', path: '/industries' },
  { name: 'Contact', path: '/contact' }
];

const legalLinks = [
  { name: 'Privacy Policy', path: '/legal/privacy' },
  { name: 'Terms of Service', path: '/legal/terms' },
  { name: 'Cookie Policy', path: '/legal/cookies' },
  { name: 'GDPR', path: '/legal/gdpr' }
];

export default function Footer() {
  useScrollToTop();

  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1 md:col-span-2">
            <Link to="/" className="flex items-center">
              <Phone className="h-8 w-8 text-blue-400" />
              <span className="ml-2 text-xl font-bold">AI Receptionist Agency</span>
            </Link>
            <p className="mt-4 text-gray-400 max-w-md">
              Revolutionizing business communication with AI-powered virtual receptionists. Available 24/7 to handle your calls professionally and efficiently.
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {quickLinks.map((link) => (
                <li key={link.name}>
                  <Link 
                    to={link.path} 
                    className="text-gray-400 hover:text-blue-400"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              {legalLinks.map((link) => (
                <li key={link.name}>
                  <Link 
                    to={link.path} 
                    className="text-gray-400 hover:text-blue-400"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} AI Receptionist Agency. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}