import React from 'react';
import { Brain, Globe, Shield } from 'lucide-react';
import PageLayout from '../../components/PageLayout';

const features = [
  {
    icon: Brain,
    title: 'Enterprise AI Intelligence',
    description: 'Advanced natural language processing for human-like conversations.',
    capabilities: [
      'Context awareness',
      'Intent recognition',
      'Real-time responses',
      'Multi-turn dialogue'
    ]
  },
  {
    icon: Globe,
    title: 'Multi-Language Support',
    description: 'Support customers in their preferred language.',
    capabilities: [
      'Multiple language options',
      'Natural translations',
      'Regional accent support',
      'Cultural awareness'
    ]
  },
  {
    icon: Shield,
    title: 'Enterprise Security',
    description: 'Industry-standard security and compliance.',
    capabilities: [
      'Encrypted communication',
      'Secure data handling',
      'Compliance certifications',
      'Access controls'
    ]
  }
];

export default function VoiceAssistant() {
  return (
    <PageLayout
      title="AI Voice Assistant"
      subtitle="Enterprise-grade voice AI technology for natural, human-like interactions"
    >
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div>
          <div className="mt-6">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className="mt-10 first:mt-0">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <Icon className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {feature.title}
                      </h3>
                      <p className="mt-2 text-base text-gray-500">
                        {feature.description}
                      </p>
                      <ul className="mt-3 space-y-2">
                        {feature.capabilities.map((capability, capIndex) => (
                          <li key={capIndex} className="flex items-center text-gray-500 text-sm">
                            <div className="h-1.5 w-1.5 rounded-full bg-blue-600 mr-2"></div>
                            {capability}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        
        <div className="mt-10 lg:mt-0">
          <div className="aspect-w-16 aspect-h-9">
            <img
              className="rounded-lg shadow-lg object-cover"
              src="https://images.unsplash.com/photo-1599658880436-c61792e70672?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
              alt="AI Voice Technology"
            />
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Ready to transform your communication?</h2>
            <p className="mt-4 text-gray-600">
              Experience the future of business communication with our enterprise voice AI.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                Start Free Trial
              </button>
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50">
                Schedule Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}