import React from 'react';
import { Check } from 'lucide-react';
import PageLayout from '../components/PageLayout';

const plans = [
  {
    name: 'Basic',
    price: 149,
    setupFee: 999,
    description: 'Perfect for small businesses',
    minutes: 1000,
    overageRate: 0.20,
    setupFeatures: [
      'Basic prompt engineering',
      'Single phone line setup',
      'Standard integrations',
      'Basic workflow configuration'
    ],
    features: [
      { name: '1,000 minutes included', included: true },
      { name: 'Single phone line', included: true },
      { name: 'Business hours coverage', included: true },
      { name: 'Basic call screening', included: true },
      { name: 'Message taking', included: true },
      { name: 'Email notifications', included: true },
      { name: 'Basic reporting', included: true },
      { name: 'Multiple phone lines', included: false },
      { name: 'CRM integration', included: false }
    ]
  },
  {
    name: 'Professional',
    price: 299,
    setupFee: 1999,
    description: 'Most popular choice',
    minutes: 2500,
    overageRate: 0.18,
    popular: true,
    setupFeatures: [
      'Custom prompt engineering',
      'Multiple phone lines',
      'Advanced integrations',
      'Industry-specific workflows'
    ],
    features: [
      { name: '2,500 minutes included', included: true },
      { name: 'Up to 3 phone lines', included: true },
      { name: '24/7 coverage', included: true },
      { name: 'Advanced call screening', included: true },
      { name: 'Message taking', included: true },
      { name: 'SMS & email notifications', included: true },
      { name: 'Advanced analytics', included: true },
      { name: 'Custom greeting', included: true },
      { name: 'CRM integration', included: true },
      { name: 'Priority support', included: true }
    ]
  },
  {
    name: 'Enterprise',
    price: 'Custom',
    setupFee: '4,999+',
    description: 'For large organizations',
    setupFeatures: [
      'Custom development',
      'Multiple locations',
      'Complex integrations',
      'Custom workflows'
    ],
    features: [
      { name: 'Unlimited minutes', included: true },
      { name: 'Unlimited phone lines', included: true },
      { name: '24/7 coverage', included: true },
      { name: 'Advanced call screening', included: true },
      { name: 'Custom AI training', included: true },
      { name: 'Full customization', included: true },
      { name: 'Enterprise analytics', included: true },
      { name: 'Custom integrations', included: true },
      { name: 'Dedicated support', included: true },
      { name: 'SLA guarantees', included: true }
    ]
  }
];

export default function Pricing() {
  return (
    <PageLayout
      title="Simple, Transparent Pricing"
      subtitle="Choose the perfect plan for your business needs"
    >
      <div className="mt-12 space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
        {plans.map((plan, planIdx) => (
          <div
            key={plan.name}
            className={`relative flex flex-col rounded-2xl border ${
              plan.popular ? 'border-blue-600 shadow-xl scale-105' : 'border-gray-200'
            } bg-white p-8`}
          >
            {plan.popular && (
              <div className="absolute -top-5 right-0 left-0 mx-auto w-32 rounded-full bg-blue-600 px-3 py-1 text-sm font-semibold text-white text-center">
                Most Popular
              </div>
            )}

            <div className="mb-8">
              <h3 className="text-2xl font-bold text-gray-900">{plan.name}</h3>
              <p className="mt-4 text-sm text-gray-500">{plan.description}</p>
              
              {/* Monthly Fee */}
              <div className="mt-8">
                <span className="text-4xl font-bold text-gray-900">
                  {typeof plan.price === 'number' ? `$${plan.price}` : plan.price}
                </span>
                {typeof plan.price === 'number' && (
                  <span className="text-base font-medium text-gray-500">/month</span>
                )}
              </div>
              {plan.minutes && (
                <p className="mt-2 text-sm text-gray-500">
                  {plan.minutes.toLocaleString()} minutes included
                  {plan.overageRate && ` • ${plan.overageRate.toFixed(2)}/min overage`}
                </p>
              )}

              {/* Setup Fee */}
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                <p className="text-sm font-medium text-gray-700">One-time setup fee</p>
                <p className="mt-1 text-2xl font-bold text-gray-900">${plan.setupFee}</p>
                <div className="mt-3">
                  <p className="text-sm font-medium text-gray-700 mb-2">Includes:</p>
                  <ul className="space-y-2">
                    {plan.setupFeatures.map((feature, idx) => (
                      <li key={idx} className="flex items-center text-sm text-gray-600">
                        <Check className="h-4 w-4 text-blue-600 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <ul className="space-y-4 flex-1">
              {plan.features.map((feature, featureIdx) => (
                <li key={featureIdx} className="flex items-start">
                  <div className="flex-shrink-0">
                    <Check className={`h-6 w-6 ${feature.included ? 'text-blue-600' : 'text-gray-300'}`} />
                  </div>
                  <p className={`ml-3 text-base ${feature.included ? 'text-gray-700' : 'text-gray-500'}`}>
                    {feature.name}
                  </p>
                </li>
              ))}
            </ul>

            <div className="mt-8">
              <a
                href={plan.name === 'Enterprise' ? '/contact' : 'https://tidycal.com/3zn4zw3/free-strategy-call-for-ai-receptionist'}
                className={`block w-full rounded-full px-6 py-3 text-center text-sm font-semibold ${
                  plan.popular
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                } transition-colors duration-200`}
              >
                {plan.name === 'Enterprise' ? 'Contact Sales' : 'Get Started'}
              </a>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-16 bg-blue-50 rounded-2xl p-8">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900">Need a custom solution?</h2>
          <p className="mt-4 text-gray-600">
            Contact our sales team for a personalized quote based on your specific needs.
          </p>
          <div className="mt-8">
            <a
              href="/contact"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700"
            >
              Talk to Sales
            </a>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}