import React from 'react';
import { Stethoscope, Shield, Clock, MessageSquare, ArrowRight } from 'lucide-react';
import PageLayout from '../../components/PageLayout';

export default function Healthcare() {
  const features = [
    {
      icon: Shield,
      title: 'HIPAA Compliant',
      description: 'Fully compliant telehealth communication handling with secure data protection.'
    },
    {
      icon: Clock,
      title: 'Appointment Management',
      description: 'Smart scheduling and appointment reminders for patient care.'
    },
    {
      icon: MessageSquare,
      title: 'Patient Communication',
      description: 'Handle patient inquiries and route urgent matters appropriately.'
    }
  ];

  const capabilities = [
    'Telehealth scheduling',
    'Patient intake',
    'Appointment reminders',
    'Urgent care routing',
    'Insurance verification',
    'Follow-up coordination'
  ];

  return (
    <PageLayout
      title="Healthcare Solutions"
      subtitle="HIPAA-compliant AI reception services for medical practices"
    >
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div>
          <div className="mt-6">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className="mt-10 first:mt-0">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <Icon className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {feature.title}
                      </h3>
                      <p className="mt-2 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        
        <div className="mt-10 lg:mt-0">
          <div className="aspect-w-16 aspect-h-9">
            <img
              className="rounded-lg shadow-lg object-cover"
              src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
              alt="Healthcare Practice"
            />
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="bg-gray-50 rounded-2xl p-8">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold text-gray-900 text-center">
              Healthcare Capabilities
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
              {capabilities.map((capability, index) => (
                <div
                  key={index}
                  className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm hover:border-blue-400"
                >
                  <div className="flex-shrink-0">
                    <Stethoscope className="h-6 w-6 text-blue-600" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">{capability}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Ready to enhance patient care?</h2>
            <p className="mt-4 text-gray-600">
              Let our HIPAA-compliant AI handle patient communications while you focus on care delivery.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                Start Free Trial <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50">
                Schedule Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}