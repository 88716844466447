import React, { useEffect } from 'react';
import PageLayout from '../components/PageLayout';

export default function Booking() {
  useEffect(() => {
    // Load TidyCal script
    const script = document.createElement('script');
    script.src = 'https://asset-tidycal.b-cdn.net/js/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PageLayout
      title="Schedule a Free Strategy Call"
      subtitle="Book a personalized demo and consultation with our team"
    >
      <div className="max-w-4xl mx-auto">
        <div className="mt-12 bg-white rounded-2xl shadow-lg p-8 border border-gray-100">
          <div className="tidycal-embed" data-path="3zn4zw3/free-strategy-call-for-ai-receptionist"></div>
        </div>

        <div className="mt-8 bg-blue-50 rounded-xl p-6 text-center">
          <h3 className="text-lg font-semibold text-gray-900">What to Expect</h3>
          <p className="mt-2 text-gray-600">
            During this 30-minute call, we'll discuss your business needs and show you how our AI receptionist can transform your communication.
          </p>
        </div>
      </div>
    </PageLayout>
  );
}