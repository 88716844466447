import React from 'react';
import { Briefcase, Calendar, MessageSquare, ArrowRight } from 'lucide-react';
import PageLayout from '../../components/PageLayout';

export default function ProfessionalServices() {
  const features = [
    {
      icon: Briefcase,
      title: 'Client Management',
      description: 'Professional client intake and appointment scheduling.'
    },
    {
      icon: Calendar,
      title: 'Scheduling',
      description: 'Intelligent calendar management and meeting coordination.'
    },
    {
      icon: MessageSquare,
      title: 'Communication',
      description: 'Seamless client communication and follow-up handling.'
    }
  ];

  const capabilities = [
    'Client intake',
    'Appointment scheduling',
    'Service inquiries',
    'Document requests',
    'Follow-up management',
    'Emergency handling'
  ];

  return (
    <PageLayout
      title="Professional Services Solutions"
      subtitle="AI reception services for consultants, accountants, and professional service providers"
    >
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div>
          <div className="mt-6">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className="mt-10 first:mt-0">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <Icon className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {feature.title}
                      </h3>
                      <p className="mt-2 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        
        <div className="mt-10 lg:mt-0">
          <div className="aspect-w-16 aspect-h-9">
            <img
              className="rounded-lg shadow-lg object-cover"
              src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
              alt="Professional Services"
            />
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="bg-gray-50 rounded-2xl p-8">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold text-gray-900 text-center">
              Service Capabilities
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
              {capabilities.map((capability, index) => (
                <div
                  key={index}
                  className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm hover:border-blue-400"
                >
                  <div className="flex-shrink-0">
                    <Briefcase className="h-6 w-6 text-blue-600" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">{capability}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Ready to elevate your professional services?</h2>
            <p className="mt-4 text-gray-600">
              Let our AI handle your client communications while you focus on delivering value.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                Start Free Trial <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50">
                Schedule Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}