import React from 'react';
import { Calendar, Clock, Zap, ArrowRight } from 'lucide-react';
import PageLayout from '../../components/PageLayout';

export default function Scheduling() {
  const features = [
    {
      icon: Calendar,
      title: 'Smart Calendar Management',
      description: 'AI-powered scheduling that automatically handles appointment booking, rescheduling, and cancellations.'
    },
    {
      icon: Clock,
      title: 'Real-time Availability',
      description: 'Syncs with your calendar to ensure accurate, up-to-date availability for appointments.'
    },
    {
      icon: Zap,
      title: 'Instant Confirmations',
      description: 'Automated confirmation and reminder messages sent to both parties.'
    }
  ];

  return (
    <PageLayout
      title="Smart AI Scheduling"
      subtitle="Effortless appointment scheduling powered by artificial intelligence"
    >
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div>
          <div className="mt-6">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className="mt-10 first:mt-0">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <Icon className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {feature.title}
                      </h3>
                      <p className="mt-2 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        
        <div className="mt-10 lg:mt-0">
          <div className="aspect-w-16 aspect-h-9">
            <img
              className="rounded-lg shadow-lg object-cover"
              src="https://images.unsplash.com/photo-1611224923853-80b023f02d71?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
              alt="AI Scheduling System"
            />
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Ready to streamline your scheduling?</h2>
            <p className="mt-4 text-gray-600">
              Let our AI handle your appointments while you focus on your business.
            </p>
            <button className="mt-8 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
              Get Started <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}