import React from 'react';
import { Link } from 'react-router-dom';
import { Phone } from 'lucide-react';
import CTAButton from './CTAButton';

export default function Navbar() {
  return (
    <header className="fixed w-full bg-white shadow-sm z-50">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center">
              <Phone className="h-8 w-8 text-blue-600" />
              <span className="ml-2 text-xl font-bold">AI Receptionist Agency</span>
            </Link>
          </div>

          {/* Main Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-8">
            <div className="relative group">
              <button className="text-gray-900 group-hover:text-blue-600 px-3 py-2 text-sm font-medium">
                Products
              </button>
              <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                <div className="py-1">
                  <Link to="/features/voice-assistant" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Voice Assistant</Link>
                  <Link to="/features/call-answering" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Call Answering</Link>
                  <Link to="/features/outreach" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Outreach Campaigns</Link>
                  <Link to="/features/analytics" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Analytics</Link>
                </div>
              </div>
            </div>

            <div className="relative group">
              <button className="text-gray-900 group-hover:text-blue-600 px-3 py-2 text-sm font-medium">
                Solutions
              </button>
              <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                <div className="py-1">
                  <Link to="/industries/legal" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Legal</Link>
                  <Link to="/industries/healthcare" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Healthcare</Link>
                  <Link to="/industries/real-estate" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Real Estate</Link>
                  <Link to="/industries/professional-services" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Professional Services</Link>
                </div>
              </div>
            </div>

            <Link to="/pricing" className="text-gray-900 hover:text-blue-600 px-3 py-2 text-sm font-medium">
              Pricing
            </Link>

            <div className="relative group">
              <button className="text-gray-900 group-hover:text-blue-600 px-3 py-2 text-sm font-medium">
                Company
              </button>
              <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                <div className="py-1">
                  <Link to="/company/about" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">About</Link>
                  <Link to="/company/careers" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Careers</Link>
                  <Link to="/contact" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Contact</Link>
                </div>
              </div>
            </div>
          </div>

          {/* Get Started Button */}
          <div className="hidden md:block">
            <CTAButton href="https://tidycal.com/3zn4zw3/free-strategy-call-for-ai-receptionist" variant="primary">
              Get Started
            </CTAButton>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button className="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100">
              <span className="sr-only">Open menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}