import React from 'react';
import { ArrowRight } from 'lucide-react';

interface CTAButtonProps {
  variant?: 'primary' | 'secondary';
  children: React.ReactNode;
  className?: string;
  href?: string;
}

export default function CTAButton({ variant = 'primary', children, className = '', href }: CTAButtonProps) {
  const baseStyles = 'inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full';
  const primaryStyles = 'text-white bg-blue-600 hover:bg-blue-700';
  const secondaryStyles = 'text-blue-600 bg-white hover:bg-gray-50';
  const styles = `${baseStyles} ${variant === 'primary' ? primaryStyles : secondaryStyles} ${className}`;

  const content = (
    <>
      {children}
      <ArrowRight className="ml-2 h-5 w-5" />
    </>
  );

  // Use a button if no href is provided or if it's being used within a Link component
  if (!href) {
    return (
      <button className={styles}>
        {content}
      </button>
    );
  }

  // Use an anchor tag if href is provided
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={styles}>
      {content}
    </a>
  );
}