import React from 'react';
import { Briefcase, Building2, Stethoscope, Scale, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const industries = [
  {
    icon: Briefcase,
    title: 'Professional Services',
    description: 'Law firms, consultants, and financial advisors who need professional call handling.',
    link: '/industries/professional-services'
  },
  {
    icon: Building2,
    title: 'Real Estate',
    description: 'Property managers and real estate agents managing inquiries and showings.',
    link: '/industries/real-estate'
  },
  {
    icon: Stethoscope,
    title: 'Healthcare',
    description: 'Medical practices requiring 24/7 patient support and appointment scheduling.',
    link: '/industries/healthcare'
  },
  {
    icon: Scale,
    title: 'Legal Services',
    description: 'Law firms needing client intake and emergency call management.',
    link: '/industries/legal'
  }
];

export default function UseCases() {
  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Industries We Serve
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Tailored solutions for various business sectors
          </p>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {industries.map((industry, index) => {
            const Icon = industry.icon;
            return (
              <div key={index} className="relative group">
                <div className="h-full flex flex-col bg-white p-8 border border-gray-200 rounded-2xl transition-all duration-300 hover:shadow-lg hover:scale-105">
                  <div className="flex items-center justify-center h-12 w-12 rounded-xl bg-blue-100 text-blue-600 mb-4">
                    <Icon className="h-6 w-6" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">{industry.title}</h3>
                  <p className="text-gray-500 flex-grow">{industry.description}</p>
                  <Link to={industry.link} className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-700">
                    Learn more <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}