import React from 'react';
import { Phone, MessageCircle, BarChart, ArrowRight } from 'lucide-react';
import PageLayout from '../../components/PageLayout';

const features = [
  {
    icon: Phone,
    title: 'AI-Powered Outreach',
    description: 'Intelligent outbound calling powered by VAPI\'s natural voice technology.',
    capabilities: [
      'Natural voice interactions',
      'Dynamic conversation flow',
      'Real-time response handling',
      'Multi-language support'
    ]
  },
  {
    icon: MessageCircle,
    title: 'Smart Campaign Management',
    description: 'Automated campaign execution with intelligent routing and scheduling.',
    capabilities: [
      'Campaign scheduling',
      'Call list management',
      'Priority routing',
      'Follow-up automation'
    ]
  },
  {
    icon: BarChart,
    title: 'Performance Analytics',
    description: 'Comprehensive tracking and optimization of outreach campaigns.',
    capabilities: [
      'Call success rates',
      'Conversion tracking',
      'Cost per contact',
      'ROI measurement'
    ]
  }
];

const useCases = [
  {
    title: 'Appointment Reminders',
    description: 'Automated appointment confirmations and reminders'
  },
  {
    title: 'Follow-up Calls',
    description: 'Systematic follow-up with leads and customers'
  },
  {
    title: 'Service Updates',
    description: 'Proactive service and delivery notifications'
  },
  {
    title: 'Feedback Collection',
    description: 'Automated satisfaction surveys and feedback calls'
  }
];

export default function Outreach() {
  return (
    <PageLayout
      title="AI Outreach Campaigns"
      subtitle="Intelligent outbound communication powered by VAPI technology"
    >
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div>
          <div className="mt-6">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className="mt-10 first:mt-0">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <Icon className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {feature.title}
                      </h3>
                      <p className="mt-2 text-base text-gray-500">
                        {feature.description}
                      </p>
                      <ul className="mt-3 space-y-2">
                        {feature.capabilities.map((capability, capIndex) => (
                          <li key={capIndex} className="flex items-center text-gray-500 text-sm">
                            <div className="h-1.5 w-1.5 rounded-full bg-blue-600 mr-2"></div>
                            {capability}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        
        <div className="mt-10 lg:mt-0">
          <div className="aspect-w-16 aspect-h-9">
            <img
              className="rounded-lg shadow-lg object-cover"
              src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
              alt="Outreach Campaigns"
            />
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="bg-gray-50 rounded-2xl p-8">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold text-gray-900 text-center">
              Use Cases
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
              {useCases.map((useCase, index) => (
                <div
                  key={index}
                  className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm hover:border-blue-400"
                >
                  <div className="flex-shrink-0">
                    <Phone className="h-6 w-6 text-blue-600" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <h4 className="text-sm font-medium text-gray-900">{useCase.title}</h4>
                    <p className="mt-1 text-sm text-gray-500">{useCase.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16">
        <div className="bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Ready to start your campaign?</h2>
            <p className="mt-4 text-gray-600">
              Launch intelligent outreach campaigns powered by VAPI's advanced voice AI.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                Start Free Trial <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50">
                Schedule Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}