import React from 'react';
import { Users, Target, Award, Heart } from 'lucide-react';
import { Link } from 'react-router-dom';
import PageLayout from '../../components/PageLayout';
import CTAButton from '../../components/CTAButton';

const values = [
  {
    icon: Users,
    title: 'Customer First',
    description: 'We prioritize exceptional customer service through advanced AI technology.'
  },
  {
    icon: Target,
    title: 'Innovation',
    description: 'Continuously improving our AI technology for better service delivery.'
  },
  {
    icon: Award,
    title: 'Excellence',
    description: 'Maintaining the highest standards in AI-powered communication.'
  },
  {
    icon: Heart,
    title: 'Empathy',
    description: 'Programming our AI to understand and address customer needs with care.'
  }
];

export default function About() {
  return (
    <PageLayout
      title="About AI Receptionist Agency"
      subtitle="Transforming business communication through artificial intelligence"
    >
      <div className="mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div>
          <h2 className="text-3xl font-bold text-gray-900">Our Mission</h2>
          <p className="mt-4 text-lg text-gray-500">
            We're on a mission to revolutionize how businesses handle customer communications using advanced AI technology. Our goal is to provide 24/7 professional reception services that enhance customer experience while reducing operational costs.
          </p>
        </div>
        <div className="mt-8 lg:mt-0">
          <div className="aspect-w-16 aspect-h-9">
            <img
              className="rounded-lg shadow-lg object-cover"
              src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
              alt="Team collaboration"
            />
          </div>
        </div>
      </div>

      <div className="mt-24">
        <h2 className="text-3xl font-bold text-gray-900 text-center">Our Values</h2>
        <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {values.map((value, index) => {
            const Icon = value.icon;
            return (
              <div key={index} className="text-center">
                <div className="mx-auto h-16 w-16 bg-blue-100 rounded-full flex items-center justify-center">
                  <Icon className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="mt-4 text-xl font-semibold text-gray-900">{value.title}</h3>
                <p className="mt-2 text-gray-500">{value.description}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-24">
        <div className="bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Join Our Team</h2>
            <p className="mt-4 text-gray-600">
              Help us shape the future of business communication with AI technology.
            </p>
            <div className="mt-8 flex justify-center">
              <Link
                to="/company/careers"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700"
              >
                View Open Positions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}