import React from 'react';
import { Brain, Bot, Code } from 'lucide-react';
import PageLayout from '../../components/PageLayout';

const upcomingRoles = [
  {
    icon: Brain,
    title: 'AI Solutions Architect',
    description: 'Design and implement advanced AI communication systems.'
  },
  {
    icon: Bot,
    title: 'Automation Engineer',
    description: 'Build scalable automation workflows for enterprise clients.'
  },
  {
    icon: Code,
    title: 'AI Integration Specialist',
    description: 'Develop seamless integrations between AI systems and business tools.'
  }
];

export default function Careers() {
  return (
    <PageLayout
      title="Join Our Team"
      subtitle="Help shape the future of business communication with AI"
    >
      <div className="mt-12">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-2xl font-bold text-gray-900">
            We're Growing Fast
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            At AI Receptionist Agency, we're building the next generation of intelligent business communication. 
            We're looking for passionate individuals who want to be at the forefront of AI innovation.
          </p>
        </div>

        <div className="mt-16">
          <h3 className="text-xl font-semibold text-gray-900 mb-8">
            Upcoming Opportunities
          </h3>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
            {upcomingRoles.map((role, index) => {
              const Icon = role.icon;
              return (
                <div key={index} className="bg-white rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-all duration-300">
                  <div className="h-12 w-12 bg-blue-100 rounded-xl flex items-center justify-center mb-4">
                    <Icon className="h-6 w-6 text-blue-600" />
                  </div>
                  <h4 className="text-lg font-semibold text-gray-900 mb-2">
                    {role.title}
                  </h4>
                  <p className="text-gray-600">
                    {role.description}
                  </p>
                  <div className="mt-4">
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                      Coming Soon
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mt-16 bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Don't see the right role?</h2>
            <p className="mt-4 text-gray-600">
              We're always looking for talented individuals passionate about AI and automation. 
              Drop us a line and tell us how you can contribute to our mission.
            </p>
            <div className="mt-8 flex justify-center">
              <a
                href="mailto:hello@aireceptionistagency.com"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="max-w-3xl mx-auto">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              Why Join Us?
            </h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <div className="h-6 w-6 rounded-full bg-blue-100 flex items-center justify-center">
                    <div className="h-2 w-2 rounded-full bg-blue-600"></div>
                  </div>
                </div>
                <p className="ml-3 text-gray-600">
                  Work with cutting-edge AI technology and shape the future of business communication
                </p>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <div className="h-6 w-6 rounded-full bg-blue-100 flex items-center justify-center">
                    <div className="h-2 w-2 rounded-full bg-blue-600"></div>
                  </div>
                </div>
                <p className="ml-3 text-gray-600">
                  Join a fast-growing team of innovators and problem solvers
                </p>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <div className="h-6 w-6 rounded-full bg-blue-100 flex items-center justify-center">
                    <div className="h-2 w-2 rounded-full bg-blue-600"></div>
                  </div>
                </div>
                <p className="ml-3 text-gray-600">
                  Remote-first culture with flexible work arrangements
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}