import React from 'react';
import { MessageSquare, Clock, CheckCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import CTAButton from './CTAButton';

export default function Hero() {
  return (
    <div className="pt-24 pb-16 bg-gradient-to-br from-primary-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <div className="inline-flex items-center px-4 py-1.5 rounded-full bg-primary-100 mb-6">
              <span className="text-sm font-medium text-primary-800">New: Multi-language Support</span>
            </div>
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">24/7 Live</span>
              <span className="block text-primary-600">Virtual Receptionists</span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Never miss a call again. Our AI receptionists handle your calls 24/7, schedule appointments, and manage customer inquiries with human-like interaction.
            </p>
            
            <div className="mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <CTAButton href="https://tidycal.com/3zn4zw3/free-strategy-call-for-ai-receptionist" variant="primary">
                  Get Started
                </CTAButton>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <Link to="/pricing">
                  <CTAButton variant="secondary">
                    View Pricing
                  </CTAButton>
                </Link>
              </div>
            </div>

            <div className="mt-8 grid grid-cols-3 gap-4">
              <div className="flex items-center">
                <MessageSquare className="h-5 w-5 text-primary-600" />
                <span className="ml-2 text-sm text-gray-500">24/7 Support</span>
              </div>
              <div className="flex items-center">
                <Clock className="h-5 w-5 text-primary-600" />
                <span className="ml-2 text-sm text-gray-500">Instant Setup</span>
              </div>
              <div className="flex items-center">
                <CheckCircle className="h-5 w-5 text-primary-600" />
                <span className="ml-2 text-sm text-gray-500">No Contract</span>
              </div>
            </div>
          </div>
          
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
              <img
                className="w-full rounded-lg"
                src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
                alt="AI Receptionist at work"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}