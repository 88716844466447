import React from 'react';
import PageLayout from '../../components/PageLayout';

const sections = [
  {
    title: 'Acceptance of Terms',
    content: [
      'By accessing and using our services, you agree to be bound by these Terms of Service.',
      'You must be at least 18 years old to use our services.',
      'You are responsible for ensuring your use complies with all applicable laws.',
      'We reserve the right to modify these terms at any time.'
    ]
  },
  {
    title: 'Use of Services',
    content: [
      'Provide accurate and complete information',
      'Maintain the security of your account credentials',
      'Use services in compliance with all applicable laws',
      'Not misuse or abuse our services',
      'Not attempt to access restricted areas',
      'Not interfere with service operation'
    ]
  },
  {
    title: 'Service Availability',
    content: [
      '99.9% uptime guarantee for enterprise plans',
      'Scheduled maintenance windows',
      'Emergency maintenance when required',
      'Automatic failover and redundancy',
      'Real-time status monitoring',
      'Incident response procedures'
    ]
  },
  {
    title: 'Payment Terms',
    content: [
      'Subscription fees billed monthly or annually',
      'All fees are non-refundable unless required by law',
      'Automatic renewal unless cancelled',
      '30-day notice required for cancellation',
      'Pro-rated refunds for annual plans',
      'Price changes with 30-day notice'
    ]
  },
  {
    title: 'Termination',
    content: [
      'Right to terminate for violations',
      'Immediate suspension for security threats',
      'Data retention after termination',
      'Export options before termination',
      'Final billing resolution',
      'Service wind-down procedures'
    ]
  }
];

export default function Terms() {
  return (
    <PageLayout
      title="Terms of Service"
      subtitle="Please read these terms carefully before using our services"
    >
      <div className="mt-12">
        <div className="text-sm text-gray-500 mb-8">
          Last updated: {new Date().toLocaleDateString()}
        </div>

        <div className="prose prose-blue max-w-none">
          <p className="text-lg text-gray-600">
            These Terms of Service ("Terms") govern your access to and use of AI Receptionist Agency's services. By using our services, you agree to be bound by these terms.
          </p>

          <div className="mt-12 space-y-12">
            {sections.map((section, index) => (
              <div key={index} className="bg-white rounded-xl border border-gray-200 p-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                  {index + 1}. {section.title}
                </h2>
                <ul className="space-y-4">
                  {section.content.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-start">
                      <div className="flex-shrink-0 h-2 w-2 mt-2 rounded-full bg-blue-600"></div>
                      <span className="ml-4 text-gray-600">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="mt-12 bg-blue-50 rounded-2xl p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
            <p className="text-gray-600 mb-6">
              If you have any questions about these Terms of Service, please contact our legal team at:
            </p>
            <div className="space-y-2">
              <p className="text-gray-900">Email: <a href="mailto:legal@aireceptionist.com" className="text-blue-600 hover:text-blue-800">legal@aireceptionist.com</a></p>
              <p className="text-gray-900">Phone: +1 (555) 123-4567</p>
              <p className="text-gray-900">Address: 123 Innovation Drive, Silicon Valley, CA 94025</p>
            </div>
          </div>

          <div className="mt-12 text-sm text-gray-500">
            <p>
              These Terms of Service are effective as of {new Date().toLocaleDateString()} and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
            </p>
            <p className="mt-4">
              We reserve the right to update or change our Terms of Service at any time. Your continued use of the Service after we post any modifications to the Terms of Service on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Terms of Service.
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}