import React from 'react';
import PageLayout from '../../components/PageLayout';

const sections = [
  {
    title: 'Information We Collect',
    content: [
      'Contact information (name, email, phone)',
      'Account credentials',
      'Communication preferences',
      'Usage data and analytics',
      'Call recordings and transcripts',
      'Integration data from third-party services'
    ]
  },
  {
    title: 'How We Use Your Information',
    content: [
      'Provide and maintain our services',
      'Improve and personalize your experience',
      'Process transactions and billing',
      'Send service updates and notifications',
      'Analyze service usage and performance',
      'Prevent fraud and ensure security'
    ]
  },
  {
    title: 'Data Security',
    content: [
      'Enterprise-grade encryption',
      'Regular security audits',
      'Secure data centers',
      'Access control and monitoring',
      'Incident response procedures',
      'Compliance with security standards'
    ]
  },
  {
    title: 'Your Rights',
    content: [
      'Access your personal information',
      'Request data correction',
      'Delete your account and data',
      'Export your data',
      'Opt-out of communications',
      'Object to data processing'
    ]
  }
];

export default function Privacy() {
  return (
    <PageLayout
      title="Privacy Policy"
      subtitle="How we protect and handle your data"
    >
      <div className="mt-12">
        <div className="text-sm text-gray-500 mb-8">
          Last updated: {new Date().toLocaleDateString()}
        </div>

        <div className="prose prose-blue max-w-none">
          <p className="text-lg text-gray-600">
            At AI Receptionist Agency, we take your privacy seriously. This policy describes how we collect, use, and protect your personal information when you use our services.
          </p>

          <div className="mt-12 space-y-12">
            {sections.map((section, index) => (
              <div key={index} className="bg-white rounded-xl border border-gray-200 p-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                  {index + 1}. {section.title}
                </h2>
                <ul className="space-y-4">
                  {section.content.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-start">
                      <div className="flex-shrink-0 h-2 w-2 mt-2 rounded-full bg-blue-600"></div>
                      <span className="ml-4 text-gray-600">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="mt-12 bg-blue-50 rounded-2xl p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
            <p className="text-gray-600 mb-6">
              If you have any questions about this Privacy Policy or our data practices, please contact our Data Protection Officer at:
            </p>
            <div className="space-y-2">
              <p className="text-gray-900">Email: <a href="mailto:privacy@aireceptionist.com" className="text-blue-600 hover:text-blue-800">privacy@aireceptionist.com</a></p>
              <p className="text-gray-900">Phone: +1 (555) 123-4567</p>
              <p className="text-gray-900">Address: 123 Innovation Drive, Silicon Valley, CA 94025</p>
            </div>
          </div>

          <div className="mt-12 text-sm text-gray-500">
            <p>
              This privacy policy is effective as of {new Date().toLocaleDateString()} and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
            </p>
            <p className="mt-4">
              We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}