import React from 'react';
import PageLayout from '../../components/PageLayout';

const sections = [
  {
    title: 'What Are Cookies',
    content: [
      'Small text files stored on your device',
      'Help remember your preferences',
      'Track website usage patterns',
      'Enable personalized features',
      'Improve site performance'
    ]
  },
  {
    title: 'How We Use Cookies',
    content: [
      'Remember your login status',
      'Customize your experience',
      'Analyze site traffic',
      'Track marketing effectiveness',
      'Improve our services',
      'Enable essential features'
    ]
  },
  {
    title: 'Types of Cookies We Use',
    content: [
      'Essential cookies - Required for basic functionality',
      'Performance cookies - Track usage patterns',
      'Functionality cookies - Remember your preferences',
      'Analytics cookies - Help us improve',
      'Marketing cookies - Support our advertising',
      'Third-party cookies - From trusted partners'
    ]
  },
  {
    title: 'Managing Cookies',
    content: [
      'Control through browser settings',
      'Block specific cookie types',
      'Delete existing cookies',
      'Set cookie preferences',
      'Opt-out options available',
      'Third-party tool controls'
    ]
  }
];

export default function Cookies() {
  return (
    <PageLayout
      title="Cookie Policy"
      subtitle="Understanding how we use cookies to improve your experience"
    >
      <div className="mt-12">
        <div className="text-sm text-gray-500 mb-8">
          Last updated: {new Date().toLocaleDateString()}
        </div>

        <div className="prose prose-blue max-w-none">
          <p className="text-lg text-gray-600">
            This Cookie Policy explains how AI Receptionist Agency uses cookies and similar technologies to recognize you when you visit our website and use our services. It explains what these technologies are and why we use them, as well as your rights to control our use of them.
          </p>

          <div className="mt-12 space-y-12">
            {sections.map((section, index) => (
              <div key={index} className="bg-white rounded-xl border border-gray-200 p-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                  {index + 1}. {section.title}
                </h2>
                <ul className="space-y-4">
                  {section.content.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-start">
                      <div className="flex-shrink-0 h-2 w-2 mt-2 rounded-full bg-blue-600"></div>
                      <span className="ml-4 text-gray-600">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="mt-12 bg-blue-50 rounded-2xl p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Cookie Preferences</h2>
            <p className="text-gray-600 mb-6">
              You can change your cookie preferences at any time by clicking the "Cookie Settings" button below. You can also manage your settings through your browser.
            </p>
            <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
              Cookie Settings
            </button>
          </div>

          <div className="mt-12 bg-white rounded-xl border border-gray-200 p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
            <p className="text-gray-600 mb-6">
              If you have any questions about our Cookie Policy, please contact our privacy team at:
            </p>
            <div className="space-y-2">
              <p className="text-gray-900">Email: <a href="mailto:privacy@aireceptionist.com" className="text-blue-600 hover:text-blue-800">privacy@aireceptionist.com</a></p>
              <p className="text-gray-900">Phone: +1 (555) 123-4567</p>
              <p className="text-gray-900">Address: 123 Innovation Drive, Silicon Valley, CA 94025</p>
            </div>
          </div>

          <div className="mt-12 text-sm text-gray-500">
            <p>
              This Cookie Policy is effective as of {new Date().toLocaleDateString()} and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
            </p>
            <p className="mt-4">
              We reserve the right to update or change our Cookie Policy at any time. Your continued use of the Service after we post any modifications to the Cookie Policy on this page will constitute your acknowledgment of the modifications.
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}