import React from 'react';
import { Scale, Stethoscope, Building2, Briefcase, ArrowRight } from 'lucide-react';
import PageLayout from '../components/PageLayout';

const industries = [
  {
    icon: Scale,
    title: 'Legal Services',
    description: 'HIPAA-compliant solutions for law firms requiring detailed client intake and urgent call management.',
    link: '/industries/legal'
  },
  {
    icon: Stethoscope,
    title: 'Healthcare',
    description: 'Secure, HIPAA-compliant telehealth communication and patient scheduling solutions.',
    link: '/industries/healthcare'
  },
  {
    icon: Building2,
    title: 'Real Estate',
    description: 'Automated showing scheduling and property inquiry management available 24/7.',
    link: '/industries/real-estate'
  },
  {
    icon: Briefcase,
    title: 'Professional Services',
    description: 'Streamlined client communication and appointment management for service providers.',
    link: '/industries/professional-services'
  }
];

export default function Industries() {
  return (
    <PageLayout
      title="Industries We Serve"
      subtitle="Tailored AI receptionist solutions for your specific industry needs"
    >
      <div className="mt-12 grid grid-cols-1 gap-8 md:grid-cols-2">
        {industries.map((industry, index) => {
          const Icon = industry.icon;
          return (
            <div
              key={index}
              className="bg-white rounded-xl border border-gray-200 p-8 hover:shadow-lg transition-all duration-300"
            >
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <div className="h-12 w-12 bg-blue-100 rounded-xl flex items-center justify-center">
                    <Icon className="h-6 w-6 text-blue-600" />
                  </div>
                </div>
                <h3 className="ml-4 text-xl font-bold text-gray-900">{industry.title}</h3>
              </div>
              
              <p className="mt-4 text-gray-600">{industry.description}</p>
              
              <div className="mt-8">
                <a
                  href={industry.link}
                  className="inline-flex items-center text-blue-600 hover:text-blue-700"
                >
                  Learn more <ArrowRight className="ml-2 h-4 w-4" />
                </a>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-16">
        <div className="bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Need a Custom Solution?</h2>
            <p className="mt-4 text-gray-600">
              We create tailored solutions for unique business needs.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                Contact Sales <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50">
                Schedule Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}