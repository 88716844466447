import React from 'react';
import PageLayout from '../../components/PageLayout';

const sections = [
  {
    title: 'Data Protection Rights',
    content: [
      'Right to access your personal data',
      'Right to rectification of inaccurate data',
      'Right to erasure ("right to be forgotten")',
      'Right to restrict processing',
      'Right to data portability',
      'Right to object to processing',
      'Rights related to automated decision making'
    ]
  },
  {
    title: 'Data Processing Principles',
    content: [
      'Lawfulness, fairness, and transparency',
      'Purpose limitation',
      'Data minimization',
      'Accuracy of personal data',
      'Storage limitation',
      'Integrity and confidentiality',
      'Accountability and compliance'
    ]
  },
  {
    title: 'International Transfers',
    content: [
      'Standard contractual clauses',
      'Binding corporate rules',
      'Adequacy decisions',
      'Appropriate safeguards',
      'Data transfer impact assessments',
      'Cross-border data flow monitoring'
    ]
  },
  {
    title: 'Security Measures',
    content: [
      'Encryption of personal data',
      'Regular security assessments',
      'Access control systems',
      'Data backup procedures',
      'Incident response plans',
      'Employee training programs'
    ]
  }
];

export default function GDPR() {
  return (
    <PageLayout
      title="GDPR Compliance"
      subtitle="Our commitment to protecting your data rights under GDPR"
    >
      <div className="mt-12">
        <div className="text-sm text-gray-500 mb-8">
          Last updated: {new Date().toLocaleDateString()}
        </div>

        <div className="prose prose-blue max-w-none">
          <p className="text-lg text-gray-600">
            At AI Receptionist Agency, we are committed to protecting your personal data and ensuring compliance with the General Data Protection Regulation (GDPR). This policy outlines your rights and our obligations under the GDPR.
          </p>

          <div className="mt-12 space-y-12">
            {sections.map((section, index) => (
              <div key={index} className="bg-white rounded-xl border border-gray-200 p-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                  {index + 1}. {section.title}
                </h2>
                <ul className="space-y-4">
                  {section.content.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-start">
                      <div className="flex-shrink-0 h-2 w-2 mt-2 rounded-full bg-blue-600"></div>
                      <span className="ml-4 text-gray-600">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="mt-12 bg-blue-50 rounded-2xl p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Data Protection Officer</h2>
            <p className="text-gray-600 mb-6">
              For any GDPR-related inquiries or to exercise your data protection rights, please contact our Data Protection Officer at:
            </p>
            <div className="space-y-2">
              <p className="text-gray-900">Email: <a href="mailto:dpo@aireceptionist.com" className="text-blue-600 hover:text-blue-800">dpo@aireceptionist.com</a></p>
              <p className="text-gray-900">Phone: +1 (555) 123-4567</p>
              <p className="text-gray-900">Address: 123 Innovation Drive, Silicon Valley, CA 94025</p>
            </div>
          </div>

          <div className="mt-12 bg-white rounded-xl border border-gray-200 p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Exercise Your Rights</h2>
            <p className="text-gray-600 mb-6">
              To exercise any of your GDPR rights, please submit a formal request using our secure form:
            </p>
            <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
              Submit GDPR Request
            </button>
          </div>

          <div className="mt-12 text-sm text-gray-500">
            <p>
              This GDPR Compliance statement is effective as of {new Date().toLocaleDateString()} and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
            </p>
            <p className="mt-4">
              We reserve the right to update or change our GDPR Compliance statement at any time. Your continued use of the Service after we post any modifications will constitute your acknowledgment of the modifications.
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}